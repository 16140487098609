<template>
    <div>
		<!-- 头部 -->
		<Header-component></Header-component>
		<!-- 菜单 -->
		<side-bar/>
		<!-- 右侧主体内容 -->
		<div class="main-container">
			<router-view></router-view>
			<div class="main-footer">
				<Footer></Footer>
			</div>
		</div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: "base-layout",
  data() {
    return {
      
    };
  },
  computed: {
	...mapState(['sidebarOpend'])
  },
  methods: {
    
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variable.scss';
.main-container{
	transition: margin-left .28s;
	margin-left: $menuWidth;
	padding-top: $headerBarH + 20px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	position: relative;
}
.main-footer{
	width: 100%;
	text-align: center;
	margin-top: 20px;
}
</style>