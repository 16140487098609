<template>
	<div class="footer">
		<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022104912号-3</a> 
		<span>广东鑫粤拍卖有限公司版权所有</span>
	</div>
</template>

<script>
export default {
	data() {
		return {
			
		}
	}
}
</script>

<style scoped lang="scss">
	.footer{
		width: 100%;
		text-align: center;
		font-size: 12px;
		color: #999;
		line-height: 20px;
		span{
			padding-left: 10px;
		}
	}
</style>